import { cacheGetByName, cacheSetByName } from './productTourCache'

const Z_INDEX = '51'
const TARGET_PREFIX = '-target'
const MODAL_PREFIX = '-modal'

type CurrentTour = {
  [key: string]: boolean
}

const currentTour = reactive<CurrentTour>({})

export function useProductTour(productTourName: string) {
  const isTourActive = computed(() => !!currentTour[productTourName])
  function show() {
    if (cacheGetByName(productTourName)) return
    const modalNode = getModalNode()
    const targetNode = getTargetNode()
    if (!modalNode || !targetNode) return
    setTargetZIndex(Z_INDEX)
    currentTour[productTourName] = true
  }

  function complete() {
    cacheSetByName(productTourName, true)
    setTargetZIndex('')
    currentTour[productTourName] = false
    return true
  }

  function isComplete() {
    return cacheGetByName(productTourName) === true
  }

  function targetOn(event: keyof HTMLElementEventMap, callback: EventListener) {
    const targetNode = getTargetNode()
    if (!targetNode) return
    targetNode.addEventListener(event, callback)
  }

  function targetOff(event: keyof HTMLElementEventMap, callback: EventListener) {
    const targetNode = getTargetNode()
    if (!targetNode) return
    targetNode.removeEventListener(event, callback)
  }

  function getElementByAttribute(name: string): HTMLDivElement | null {
    return document.querySelector(`[data-product-tour="${name}"]`)
  }

  function setTargetZIndex(zIndex: string) {
    getTargetNode()?.style.setProperty('z-index', zIndex)
  }

  function getModalNode(): HTMLDivElement | null {
    return getElementByAttribute(productTourName + MODAL_PREFIX)
  }

  function getTargetNode(): HTMLDivElement | null {
    return getElementByAttribute(productTourName + TARGET_PREFIX)
  }

  return {
    show,
    complete,
    targetOn,
    targetOff,
    isComplete,
    isTourActive,
  }
}
