import { useLocalStorage } from '@vueuse/core'

const PRODUCT_TOUR_CACHE = 'product-tour'
const PRODUCT_TOUR_DEFAULT = {}

export function cacheGetObject() {
  return useLocalStorage<any>(PRODUCT_TOUR_CACHE, PRODUCT_TOUR_DEFAULT).value
}

export function cacheGetByName(name: string) {
  return cacheGetObject()[name]
}

export function cacheSetByName(name: string, value: boolean) {
  cacheGetObject()[name] = value
}
